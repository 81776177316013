<template>
  <div class="relative">
  <div class="relative mt-1 rounded-md shadow-sm">
    <div
      v-if="icon"
      class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none "
    >
      <FontAwesomeIcon class="text-xl text-custom-green" :icon="icon" />
    </div>
    <input
      :type="type"
      :class="inputClass"
      class="block w-full py-3 pl-10 text-lg border-0 shadow focus:border-b-1 focus:border-custom-green focus:ring-0 focus:shadow-lg ring-2 ring-gray-100"
      :placeholder="placeholder"
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
    />
  </div>
    <div if="errors" class="mt-1 text-sm text-red-500">{{errors}}</div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
  props: {
    icon: {
      default: null
    },
    errors: {
      default: null
    },
    modelValue: { type: String },
    placeholder: { default: null },
    inputClass: { default: null },
    type: {
      default: 'text'
    }
  },
  components: {
    FontAwesomeIcon
  },
  setup () {}
}
</script>
