<template>
  <div class="container px-5 mx-auto md:max-w-5xl lg:max-w-7xl">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
      <div
        class="flex flex-col items-center justify-center order-2 my-5 space-y-6 md:order-1 md:space-y-10 md:items-baseline md:my-0"
      >
        <img src="@/assets/login-obj.png" class="max-w-full" />
      </div>
      <div
        class="flex flex-col items-center justify-center order-1 my-5 space-y-6 md:order-2 md:space-y-8 md:my-0"
      >
        <router-link to="/">
          <img src="@/assets/logo.png" class="mt-4 max-h-20" alt="HuskyTask" />
        </router-link>
        <h3 class="mt-5 text-3xl font-bold uppercase md:text-4xl">
          LOGIN TO YOUR ACCOUNT
        </h3>
        <div class="w-20 h-2 bg-gray-900"></div>
        <div class="flex w-full max-w-lg">
          <div class="flex flex-col flex-1 mb-20">
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/auth/LoginForm'
import { onMounted } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
  components: {
    LoginForm
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    onMounted(() => {
      store.dispatch('auth/logout').then(
        () => {
          router.push({
            name: 'Login'
          })
        },
        (error) => {
          console.error(error)
        }
      )
    })
    return {}
  }
}
</script>
