<template>
  <form @submit.prevent="onSubmit" novalidate>
    <TextInput
      type="email"
      class="mb-8"
      placeholder="Enter E-mail"
      v-model="v$.email.$model"
      :icon="faEnvelope"
      :errors="v$?.email?.$errors[0]?.$message"
    />

    <TextInput
      type="password"
      class="mb-8"
      v-model="v$.password.$model"
      placeholder="Enter Password"
      :icon="faLock"
      :errors="v$?.password?.$errors[0]?.$message"
    />
    <SubmitButton class="w-full mb-5" :isLoading="isLoading"
      >Login</SubmitButton
    >
    <div class="relative w-full">
      <div
        v-if="errors"
        class="absolute w-full p-4 font-medium text-red-800 rounded-md  bg-red-50"
      >
        {{ errors }}
      </div>
    </div>
  </form>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, email, minLength } from '@vuelidate/validators'

import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons'
import TextInput from '../TextInput.vue'
import SubmitButton from '../SubmitButton.vue'
import { reactive, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: { TextInput, SubmitButton },
  setup () {
    const isLoading = ref(false)
    const errors = ref('')
    const store = useStore()
    const router = useRouter()

    const state = reactive({
      email: '',
      password: ''
    })
    const rules = {
      email: {
        required: helpers.withMessage('Email field is required', required),
        email: helpers.withMessage(
          'Email should be a valid email address ',
          email
        )
      },
      password: {
        required: helpers.withMessage('Password field is required', required),
        minLength: minLength(6)
      }
    }
    const v$ = useVuelidate(rules, state)
    const onSubmit = () => {
      v$.value.$touch()
      if (v$.value.$invalid) return

      isLoading.value = true
      errors.value = null
      store
        .dispatch('auth/LogIn', state)
        .then(
          (data) => {
            if (data.emailVerified) {
              router.push({
                name: 'Search'
              })
            }
            errors.value =
              'Your email isn\'t verified yet. Please click the link in email you received during registration.'
          },
          (error) => {
            if (error.response.status === 400) {
              errors.value = 'Invalid username or password'
            } else {
              errors.value =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error ||
                error.toString()
            }
          }
        )
        .then(function () {
          isLoading.value = false
        })
    }
    return {
      onSubmit,
      v$,
      faEnvelope,
      faLock,
      errors,
      isLoading
    }
  }
}
</script>
